import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NanoTable from '../../../shared/components/NanoTable'
import SimpleCell from '../../../shared/components/SimpleCell'
import MoreActionOrderCell from './MoreActionOrderCell'
import CreatedByCell from '../../../shared/components/CreatedByCell'
import { useTranslation } from 'react-i18next'
import { orderDatesToString, removeDuplicates } from '../utils/orderUtils'
import { dateMediumWithTime, isAfter } from '../../../shared/utils/dateUtils'
import { client } from '../../../shared/apiClient'
import { Button, TableCell } from '@mui/material'
import { ExpandIcon } from '../../../shared/icons'
import GroupsDevicesCell from '../../../shared/components/GroupsDevicesCell'
import NotFoundData from '../../../shared/components/NotFoundData'
import useNewTabNavigate from '../../../shared/customHooks/useNewTabNavigate'

const columns = (t, disableOrderEdition, handleEdit, siloId) => [
  // {
  //   header: 'reference',
  //   width: 150,
  //   format: (row, key) => (
  //     <SimpleCell
  //       key={key}
  //       loading={row.loading}
  //       data={`${row.id?.slice(0, 5)}...${row.id?.slice(-5)}`}
  //       variant='h5'
  //     />
  //   )
  // },
  {
    header: t('order_delivery_dates'),
    width: 200,
    enableSort: true,
    format: (row) => (
      <SimpleCell
        loading={row.loading}
        data={orderDatesToString({ order: row })}
        variant="h5"
        sx={{ pr: 3 }}
      />
    ),
  },
  {
    header: t('order_time_slot'),
    width: 150,
    disableSort: true,
    format: (row) => (
      <SimpleCell
        loading={row.loading}
        data={row.timeSlot ? t(row.timeSlot) : '-'}
        variant="h5"
        sx={{ pr: 3 }}
      />
    ),
    hide: !!siloId || 'sm',
  },
  {
    header: t('quantity'),
    width: 100,
    hide: 'sm',
    format: (row, key) => (
      <SimpleCell
        key={key}
        loading={row.loading}
        data={t('number_workspace_filling_unit', { value: row.totalTonnage })}
        variant="h5"
        sx={{ pr: 3 }}
      />
    ),
  },
  {
    header: t('group_other'),
    width: 350,
    format: (row) => (
      <GroupsDevicesCell
        readOnly
        maxWidth={300}
        sx={{ pr: 3 }}
        loading={row.loading}
        device_groups_to_check={
          row.loading
            ? []
            : removeDuplicates(
                row.silos.map((s) => ({ group_name: s.farm_name, group_id: s.group_ids[0] }))
              )
        }
      />
    ),
    hide: !!siloId,
  },
  {
    header: t('order_number_to_deliver'),
    width: 100,
    format: (row, key) => (
      <SimpleCell
        key={key}
        loading={row.loading}
        data={row.silos?.length}
        sx={{ pr: 3 }}
        variant="h5"
      />
    ),
    hide: !!siloId || 'sm',
  },
  {
    header: t('order_created_by'),
    width: 250,
    hide: 'xl',
    format: (row, key) => <CreatedByCell key={key} loading={row.loading} {...row.created_by} />,
  },
  {
    header: t('created_at'),
    width: 250,
    enableSort: true,
    format: (row, key) => (
      <SimpleCell
        key={key}
        loading={row.loading}
        data={row.created_at ? dateMediumWithTime(row.created_at) : '-'}
        variant="h5"
      />
    ),
    hide: 'xl',
  },
  {
    header: 'actions',
    width: 150,
    hide: 'sm',
    format: (row) => {
      if (row.loading) {
        return null
      } else if (!siloId) {
        return (
          <MoreActionOrderCell
            loading={row.loading}
            disableOrderEdition={
              disableOrderEdition || !isAfter(new Date(row.date), new Date(Date.now()))
            }
            isValidated={row.validated}
            handleEdit={() => handleEdit(row.id)}
            {...row}
          />
        )
      } else {
        return (
          <TableCell align="right">
            <Button
              variant="outlined"
              sx={{ borderColor: 'grey.main', color: 'black !important' }}
              onClick={() => window.open(`/orders/${row.id}`)}
              startIcon={<ExpandIcon />}
            >
              {t('order_detail')}
            </Button>
          </TableCell>
        )
      }
    },
  },
]

const propTypes = {
  /** Action when click the edit item in the action menu */
  handleEdit: PropTypes.func,
  disableOrderEdition: PropTypes.bool,
  dateFilter: PropTypes.arrayOf(PropTypes.shape({})),
  groupsFilters: PropTypes.arrayOf(PropTypes.string),
  siloId: PropTypes.string,
  disableOrders: PropTypes.bool,
}

const defaultProps = {
  disableOrderEdition: false,
  disableOrders: false,
  dateFilter: [null, null],
  groupsFilters: [],
}

const OrdersList = ({
  handleEdit,
  disableOrderEdition,
  siloId,
  dateFilter,
  groupsFilters,
  disableOrders,
}) => {
  const [data, setData] = useState([])
  const { t } = useTranslation()
  const [dataLoading, setDataLoading] = useState(true)
  const newTabNavigate = useNewTabNavigate()
  const handleClick = ({ rowData, event }) =>
    !dataLoading && newTabNavigate(`/orders/${rowData.id}`, { event })
  const [sortDirection, setSortDirection] = useState(
    /** @type {import('react-virtualized').SortDirectionType} */ ('DESC')
  )
  const [sortBy, setSortBy] = useState(t('order_delivery_dates'))
  const getData = useCallback(() => {
    setDataLoading(true)
    const now = new Date()
    const date30DaysAgo = new Date(now)
    date30DaysAgo.setDate(now.getDate() - 30)
    let minDate = date30DaysAgo.toISOString().slice(0, 10)
    const date1YearLater = new Date(now)
    date1YearLater.setFullYear(now.getFullYear() + 1)
    let maxDate = date1YearLater.toISOString().slice(0, 10)
    if (dateFilter.every((d) => !!d)) {
      maxDate = dateFilter[1].format()
      minDate = dateFilter[0].format()
    }
    const query = {
      minDate,
      maxDate,
      source: 'order',
      siloId,
      ordering: `${sortDirection === 'DESC' ? '-' : ''}${sortBy === t('order_delivery_dates') ? 'order_date' : 'created_at'}`,
    }
    if (groupsFilters.length) query.groupIds = groupsFilters.join(',')
    client.GET('/v1/orders', { params: { query } }).then((orderlists) => {
      if (siloId)
        setData(
          orderlists.data.results.map((o) => ({
            ...o,
            totalTonnage: o.silos.find((s) => s.id === siloId).tonnage,
          }))
        )
      else setData(orderlists.data.results)
      setDataLoading(false)
    })
  }, [dateFilter, siloId, sortDirection, sortBy, t, groupsFilters])

  useEffect(() => {
    getData()
  }, [getData])

  if (disableOrders) {
    return <NotFoundData customText={disableOrders} />
  } else {
    return (
      <NanoTable
        columns={columns(t, disableOrderEdition, handleEdit, siloId)}
        data={data}
        loading={dataLoading}
        tableProps={{
          sortBy,
          sortDirection,
          sort: ({ sortDirection, sortBy }) => {
            setSortDirection(sortDirection)
            setSortBy(sortBy)
          },
          onRowClick: siloId ? null : handleClick, // no event on click
        }}
      />
    )
  }
}

OrdersList.defaultProps = defaultProps
OrdersList.propTypes = propTypes

export default OrdersList

import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * @callback NewTabNavigate
 * Navigate to the specified path, either in the same tab or a new tab depending on
 * the provided mouse event, or option.
 * @param {string} path absolute path to navigate to (using the same base URL as the app).
 * @param {object} [options]
 * @param {MouseEvent} [options.event]
 * @param {boolean} [options.newTab=false] True to always open in a new tab.
 */

/**
 * Navigate to the specified path, either in the same tab or a new tab depending on
 * the provided mouse event, or option.
 * @returns {NewTabNavigate} navigate
 * @example
 * ```
 * const newTabNavigate = useNewTabNavigate()
 * newTabNavigate('/path')
 * newTabNavigate('/path', { newTab: true })
 * newTabNavigate('/path', { event })
 * ```
 */
function useNewTabNavigate() {
  const routerNavigate = useNavigate()

  /** @type {NewTabNavigate} */
  const newTabNavigate = useCallback(
    /** @type {NewTabNavigate} */
    (path, options = {}) => {
      const { event, newTab = false } = options
      const useNewTab = newTab || Boolean(event && (event.ctrlKey || event.metaKey))

      if (useNewTab) {
        window.open(path, '_blank', 'noopener,noreferrer')
      } else {
        routerNavigate(path)
      }
    },
    [routerNavigate]
  )

  return newTabNavigate
}

export default useNewTabNavigate

import React, { useState, useEffect, useMemo } from 'react'
import { Crisp } from 'crisp-sdk-web'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { LicenseInfo } from '@mui/x-date-pickers-pro'
import NanolikeTheme from './config/NanolikeTheme'
import Router from './modules/layout/Router'
import LoadingPage from './shared/components/LoadingPage'
import { ToastContext } from './shared/contexts'
import Toast from './shared/components/Toast'
import { useSessionStore, useWorkspaceSettingsStore } from './shared/store'
import i18nConfig from './config/i18n'
import {
  adapterLocaleMapping,
  componentLocalesMapping,
  localTextMapping,
  setupI18nextFormatters,
} from './shared/utils/langUtils'
import { setUserProperties } from './shared/utils/analyticsUtils'
import { GlobalStyles } from '@mui/styled-engine'

import dayjs from 'dayjs'
import DayJsMinMax from 'dayjs/plugin/minMax'
import DayJsIsToday from 'dayjs/plugin/isToday'

dayjs.extend(DayJsMinMax)
dayjs.extend(DayJsIsToday)

const globalStyles = (
  <GlobalStyles
    styles={{
      // Prevent icons from being distorted
      svg: {
        objectFit: 'contain',
        flexShrink: 0,
        flexGrow: 0,
      },
    }}
  />
)

function App() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [messageToastData, setMessageToastData] = useState(null)
  const me = useSessionStore((state) => state.user)
  const currentClient = useSessionStore((state) => state.currentClient)
  const configTranslation = i18nConfig
  const state = useWorkspaceSettingsStore()

  const toastContextValue = useMemo(
    () => ({
      sendMessage: (message, severity = 'success') => {
        setMessageToastData({
          message,
          severity,
        })
      },
      handleComingSoon: () => setMessageToastData({ message: t('coming_soon'), severity: 'info' }),
    }),
    [t]
  )

  const handleToastClose = () => {
    setMessageToastData(null)
  }

  useEffect(() => {
    if (me) {
      Crisp.user.setEmail(me.email)
      Crisp.user.setNickname(`${me.first_name} ${me.last_name}`)
      Crisp.session.setData({
        workspace: currentClient.name,
        role: me.workspace_role.name,
        idUser: me.idUser,
        ...(!me.workspace_role.global && me.group_memberships
          ? { pois: me.group_memberships.map((g) => g.group.group_name).join() }
          : {}),
      })
      Crisp.user.setCompany(currentClient.name, { url: window.location.origin })
      configTranslation.changeLanguage(me.configuration.preferred_language)
      useWorkspaceSettingsStore
        .getState()
        .fetchData()
        .then(() => {
          const fillingUnit = state.getSetting('fillingUnit')?.value ?? 'ton'
          setupI18nextFormatters(fillingUnit)
        })

      setUserProperties(me.idUser, currentClient.name, me.workspace_role.name)
    }
    document.title = `BinConnect ${currentClient?.name ? `- ${currentClient.name[0].toUpperCase() + currentClient.name.toLocaleLowerCase().slice(1)}` : ''} `
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  useEffect(() => {
    Crisp.configure('eacba013-75c6-46fd-b058-99c36692deda', { sessionMerge: true })
    useSessionStore
      .getState()
      .refreshSession()
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // MATERIAL UI LICENSE
  LicenseInfo.setLicenseKey(
    'cc3197b4fde3f0914472621cab2e1164Tz01NTAwNCxFPTE3MDA5ODkzMjQ2NjcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  )
  // @ts-ignore
  const theme = createTheme(NanolikeTheme, componentLocalesMapping[configTranslation.language])
  return (
    <I18nextProvider i18n={configTranslation}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={
            adapterLocaleMapping[me?.configuration?.preferred_language] ||
            me?.configuration?.preferred_language
          }
          localeText={
            localTextMapping[me?.configuration?.preferred_language]?.components
              .MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <CssBaseline />
          {globalStyles}
          <ToastContext.Provider value={toastContextValue}>
            {loading ? <LoadingPage /> : <Router />}
            <Toast data={messageToastData} onClose={handleToastClose} />
          </ToastContext.Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </I18nextProvider>
  )
}

export default App

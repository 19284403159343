import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup, useTheme, useMediaQuery, Typography } from '@mui/material'
import DashboardDisplayMode from './models/displayModeModel'
import { ReactComponent as MapIcon } from '../../shared/icons/MapIcon.svg'
import { ReactComponent as ListIcon } from '../../shared/icons/ListIcon.svg'

const StyleToggleButton = styled(ToggleButton)(() => ({
  gap: 10,
  color: 'black',
  '&.Mui-selected': {
    backgroundColor: 'white',
  },
}))

const StyleToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(1),
    border: 0,
    '&:not(:first-of-type)': {
      borderRadius: '4px',
    },
    '&:first-of-type': {
      borderRadius: '4px',
    },
  },
}))

const propTypes = {
  onChange: PropTypes.func,
  displayMode: PropTypes.string,
}

const defaultProps = {
  onChange: null,
  displayMode: DashboardDisplayMode.List,
}

function SwitchDisplayMode({ displayMode, onChange }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('md'))

  return (
    <StyleToggleButtonGroup
      value={displayMode}
      exclusive
      onChange={onChange}
      size="small"
      sx={{ backgroundColor: 'grey.main', height: '40px' }}
    >
      <StyleToggleButton value={DashboardDisplayMode.List}>
        <ListIcon />
        <Typography variant="h5">
          {!mobileFormat && t('dashboard_switch_display_mode_list')}
        </Typography>
      </StyleToggleButton>
      <StyleToggleButton value={DashboardDisplayMode.Map}>
        <MapIcon />
        <Typography variant="h5">
          {!mobileFormat && t('dashboard_switch_display_mode_map')}
        </Typography>
      </StyleToggleButton>
    </StyleToggleButtonGroup>
  )
}

SwitchDisplayMode.propTypes = propTypes
SwitchDisplayMode.defaultProps = defaultProps

export default SwitchDisplayMode
